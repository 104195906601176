
th{
    border: none !important;
}

.table-element{
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.walletImage {
    background: $red;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-left: 2px;
}

.small-walletImage {    
    background: #E76F51;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    top: 22px;
    border: 2px solid #E76F51;
    position: relative;
    left: -10px;
}

.walletImageOutline {
    border-radius: 50%;
    border: 3px solid $red;
    width: 90px;
    height: 90px;
    padding-top: 2px;
}

.small-image-outline{
    border-radius: 50%;
    border: 3px solid $red;
    width: 32.5px;
    height: 32.5px;
    padding-top: 1px;
}

.contentWrapper {
    padding: 20px;
    margin: 0px !important;
}

.walletImageContainer {
    margin-left: 2%;
    margin-right: 2%;
    position: relative;
    top: -40px;
}

.small-image {
    padding-left: 4%;
    padding-top: 0.5%;
}

@media (min-width: 1200px) {
    .container {
        max-width: 100% !important;
    }
}

.v-div {
    border-left: 1px solid rgba(0, 0, 0, 0.25);
    height: 50px;
    width: 1px;
    margin: 0px 30px 0px 20px;
    position: relative;
    top: 8px;
}

.block-container {
    padding: 20px;
    border-radius: 10px;
    background: white;
    margin: 10px;
}

.block-container-last{
    padding: 20px;
    border-radius: 10px;
    background: white;
    margin: 10px 10px 50px 10px;
}

input {
    font-family: 'Lato', sans-serif;
    outline: none;
    border: none;
}

textarea {
    font-family: 'Lato', sans-serif;
    outline: none;
    border: none;
}

textarea:focus,
input:focus {
    font-family: 'Lato', sans-serif;
    border-color: transparent !important;
}

input::-webkit-input-placeholder {
    font-family: 'Lato', sans-serif;
    color: #999999;
}
input:-moz-placeholder {
    font-family: 'Lato', sans-serif;
    color: #999999;
}
input::-moz-placeholder {
    font-family: 'Lato', sans-serif;
    color: #999999;
}
input:-ms-input-placeholder {
    font-family: 'Lato', sans-serif;
    color: #999999;
}

textarea::-webkit-input-placeholder {
    font-family: 'Lato', sans-serif;
    color: #999999;
}
textarea:-moz-placeholder {
    font-family: 'Lato', sans-serif;
    color: #999999;
}
textarea::-moz-placeholder {
    font-family: 'Lato', sans-serif;
    color: #999999;
}
textarea:-ms-input-placeholder {
    font-family: 'Lato', sans-serif;
    color: #999999;
}

.wrap-input1 {
    font-family: 'Lato', sans-serif;
    position: relative;
    width: 100%;
    z-index: 1;
    margin-bottom: 20px;
}

.input1 {
    font-family: 'Lato', sans-serif;
    display: block;
    width: 100%;
    background: #e6e6e6;
    font-family: Montserrat-Bold;
    font-size: 15px;
    line-height: 1.5;
    color: #666666;
    border-radius: 10px;
    padding: 10px 20px;
}

.shadow-input1 {
    font-family: 'Lato', sans-serif;
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 0px 0px;
    color: rgba(87, 184, 70, 0.5);
    border-radius: 10px;
}

.input1:focus + .shadow-input1 {
    font-family: 'Lato', sans-serif;
    -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
    animation: anim-shadow 0.5s ease-in-out forwards;
}

@-webkit-keyframes anim-shadow {
    to {
        box-shadow: 0px 0px 80px 30px;
        opacity: 0;
    }
}

@keyframes anim-shadow {
    to {
        box-shadow: 0px 0px 80px 30px;
        opacity: 0;
    }
}

.inline-element{
    display: inline;
}

.inline-block-element{
    display: inline-block;
}

.align-left{
    text-align: left;
}

.fee-label{
    padding: 8.5px;
}

.align-initial {
    text-align: initial;
}

.align-end {
    text-align: end;
}

.fit-content{
    width: fit-content;
}

.v-spacer{
    width: 100%;
    height: 30px;
}

.full-page-container{
    height: 70vh;
}

.vertical-center {
    top: 15%;
    position: relative;
}

.half-page-container{
    height: 50vh;
}

.real-full-page-container{
    height: 90vh;
    width: 100%;
}

a:hover{
    text-decoration: none !important;
}

.full-width-align-center{
    text-align: center;
    width: 100%;
}

.full-width-align-left{
    text-align: left;
    width: 100%;
}

.modal-background{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0%;
    left: 0%;
    opacity: 0.25;
    background-color: black;
    z-index: 4;
}

.modal-container{
    width: 800px;
    padding: 50px 100px;
    opacity: 1;
    border-radius: 10px;
    left: 25%;
    background-color: #FAFAFA;
    z-index: 5;
    position: fixed;
    top: 35%;
    div{
        h2,h5,h6{
            width: 100%;
            text-align: center;
        }
    }
}

.level-zero{
    z-index: 0!important;
}

.sidebar-item{
    color:black !important;
}

.sidebar-item-container{
    margin:10px;
    text-align: left;
    width: 100%;
    color:black !important;
    font-size: 0.94rem;
}

.sidebar-item-container-active::before{
    width:5px;
    background: $orange;
    height: 30px;
    left: -2.5px;
    content: "";
    display: block;
    position: absolute;
    transition: 0.25s;
}

.big-logo{
    width: 300px;
}

.strong{
    font-weight: bold;
}

.verification-container{
    width: 100%;
    height: fit-content;
    border-radius: 10px;
    padding: 50px;
    border: 2px solid $light-green;
}

.logo-image{
    width: 100%;
    max-width: 200px;
    padding: 0 10px;
}

.main-container{
    overflow-y: auto;
    max-height:98vh;
    overflow: -moz-hidden-unscrollable;
    overflow: unset;
}

.stake-row-value{
    height: 0px;
    margin: 0 auto !important;
    width: 100%;
}

.footer-text{
    padding: 10px;
    position: absolute;
    bottom: 20px;
    left: 0;
}

.validation-input{
    border-bottom: 1px solid black;
    background: none;
    width: 72%;
}

.no-padding{
    padding: 0px !important;
}

.margin-auto{
    margin: 0 auto;
}

.center{
    display:flex;
    justify-content:center;
    align-items:center;
    height: 90vh;
    width: 100%;
}

.full-width{
    width: 100%;
}

tbody{
    .button{
        margin: 0 auto;
    }
}

.validation-word{
    min-width: 72%;
}

.validation-index{
    text-align: center;
    width: 15%;
    display: inline-block;
    height: 40px;
}

.validation-word-box{
    text-align: left;
}

.sidebar-footer,.sidebar-footer-network{
    position: fixed;
    bottom: 8vh;
    width: 16.666667%;
    margin: 0 -2vw;
    text-align: center;
}


.sidebar-footer-network{
    bottom: 3vh;
    font-size: 0.80rem;
}

.spinner-container{
    height: 93vh ;
}

.th{
    min-width: 150px;
}

.transaction-form{
    margin: 50px 0px;
}

.fit-content-container{
    height: fit-content;
}

.no-bg{
    background: none !important;
}

.no-scroll{
    overflow-y: unset;
}

.th-first-item{
    border-radius: 10px 0px 0px 0px;
}

.th-last-item{
    border-radius: 0px 10px 0px 0px;
}

.th-background{
    background-color: $white;
    border-top: 0px;
}

.padding-y-10{
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-y-20{
    padding-top: 20px;
    padding-bottom: 20px;
}

.padding-y-30{
    padding-top: 30px;
    padding-bottom: 30px;
}

.padding-y-40{
    padding-top: 40px;
    padding-bottom: 40px;
}

.padding-y-50{
    padding-top: 50px;
    padding-bottom: 50px;
}


.center {
    text-align: center;
}

.pagination {
    display: inline-block;
    width: fit-content;
    margin: 0 auto;
}

.pagination p {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    // border: 1px solid #ddd;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.pagination p.active {
    background-color: $orange;
    color: white;
    border: 1px solid $orange;
}

.pagination p:hover:not(.active) {background-color: #ddd;}

.table-searchbar{
    border-radius: 10px;
    padding: 2px 10px;
}

.toast {
    width: 100%;
    padding: 10px;
    position: fixed;
    background: #ccc;
    color: #666;
    font-size: 200%;
    text-align: center;
    bottom: 30px;
    right: 30px;
    transform: translateY(-100px);
    animation: slideDown 3s;
    opacity: 1 !important;
    z-index: 10;
}

@keyframes slideDown {
    0%,
    10% {
        -webkit-transform: translateY(-100px);
    }
    10%,
    90% {
        -webkit-transform: translateY(0px);
    }
}

.disabled {
    pointer-events: none;
    opacity: 0.3;
}

.error-toast{
    background: $red !important;
    color: $white !important;
}

.success-toast{
    background: $light-green !important;
    color: $white !important;
}

.first-place{
    position: absolute;
    z-index: 4;
}

.signed-message-container{
    background: #000000;
    border-radius: 10px;
    padding: 20px;
    p{
        font-size: 100%;
        color:$light-green;
        overflow-wrap: break-word;
        width: inherit;
    }
    height: 30vh;
    overflow-y: scroll;
}

#draggable-bar{
    width: 100vw;
    height: 3vh;
    position: fixed;
    left: 0px;
    top: 0;
    -webkit-app-region: drag;

}

.huge-text{
    font-size: 144px;
    color:$orange;
}

.big-text{
    font-size:48px;
}

.medium-size-box{
    width: 600px;
}

.scrollable{
    overflow-y: scroll !important;
}

.green-dot,.red-dot{
    width: 10px;
    height: 10px;
    border-radius: 10px;
    display: inline-block;
    background-color: $light-green;
}

.red-dot{
    background-color: $red;
}

.small-screen-text{
    text-align: center;
    width: 100vw;
    color: white;
    font-size: 2rem;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px none #ffffff;
    border-radius: 59px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
  ::-webkit-scrollbar-thumb:active {
    background: #000000;
  }
  ::-webkit-scrollbar-track {
    margin-top: 10px;
    background: $grey;
    border: 0px none #ffffff;
    border-radius: 100px;
  }
  ::-webkit-scrollbar-track:hover {
    background: #666666;
  }
  ::-webkit-scrollbar-track:active {
    background: #333333;
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}